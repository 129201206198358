import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fa0281e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box _center-x_" }
const _hoisted_2 = { style: {"position":"relative","display":"flex","justify-content":"flex-start","align-items":"center","width":"40%","padding-left":"10px","padding-top":"20px","padding-bottom":"10px"} }
const _hoisted_3 = {
  key: 0,
  class: "mini_spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_IcSearch = _resolveComponent("IcSearch")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.storeCompanys.spinner)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseInput, {
        label: "Pesquisar",
        type: "text",
        placeholder: "Buscar",
        value: _ctx.storeCompanys.search,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search($event.target.value)))
      }, null, 8, ["value"]),
      (_ctx.miniSpinner)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : (_openBlock(), _createBlock(_component_IcSearch, {
            key: 1,
            class: "search"
          }))
    ]),
    (_ctx.storeCompanys && _ctx.storeCompanys.Companys)
      ? (_openBlock(), _createBlock(_component_Table, {
          key: 1,
          columns: _ctx.columns,
          overflowX: "auto",
          rows: _ctx.storeCompanys.Companys,
          tableActions: _ctx.tableActions,
          onOnsort: _cache[1] || (_cache[1] = (column, direction) => {
					_ctx.getCompany(
						_ctx.storeCompanys.setCompanys,
						_ctx.storeCompanys.search,
						() => {
							_ctx.storeCompanys.setSpinner(false);
						},
						{
							order: column === 'roleName' ? 'role' : column,
							direction: direction,
						}
					);
				}
				)
        }, null, 8, ["columns", "rows", "tableActions"]))
      : _createCommentVNode("", true)
  ]))
}