import { IParticipantData } from '../dto/IParticipantData';
import { formatDate } from '@/helpers/FormatDate';
import { urlNewApi } from '@/helpers/HttpAdapterCostumer';
import { maskCpf, maskPhone } from '@/helpers/mask';
import { IParticipantState } from '@/views/Participant/dto/IParticipantState';

export function participantMapper(participantData: IParticipantData): IParticipantState {
	const participant: IParticipantState = {
		name: participantData.name || '',
		credential: participantData.nameCredential || '',
		cpf: maskCpf(participantData.cpf) || '',
		emailBusinesses: participantData.enterpriseMail || '',
		emailPersonal: participantData.mail || '',
		enterprisePhone: maskPhone(participantData.enterprisePhone) || '',
		residencePhone: maskPhone(participantData?.residence_phone) || '',
		birthday: formatDate(participantData.birthDate),
		address: participantData.address || '',
		company: participantData.company || '',
		register: participantData.register || '',
		descriptionPosition: participantData.description_role || '',
		typePosition: participantData.type_role || '',
		gene: participantData.gender,
		guestCompany: participantData.companyInvited,
		nameCompany: participantData.invitedBy,
		image: `${urlNewApi}/participant/uploads/file/${participantData.profile_img}`,
		phone: maskPhone(participantData.phone) || '',
	};
	return participant;
}
