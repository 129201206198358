
import BaseButton from "../../../../components/Atoms/BaseButton.vue";
import BaseInput from "../../../../components/Atoms/BaseInput.vue";
import Spinner from "../../../../components/Atoms/Spinner.vue";
import Table from "../../../../components/Organism/Table/table.vue";
import IcEdit from "../../../../components/icons/IcEdit.vue";
import IcSearch from "../../../../components/icons/IcSearch.vue";
import IcTrash from "../../../../components/icons/IcTrash.vue";
import IcVerticalDots from "../../../../components/icons/IcVerticalDots.vue";
import { debounce } from "../../../../helpers/debounce";
import { ICompany } from "../../../../interface/company";
import { getCompany } from "../service";
import { ROLES, useStoreCompanys } from "../store";
import { defineComponent, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "MenuTable",
	components: {
		BaseInput,
		IcTrash,
		IcSearch,
		IcEdit,
		BaseButton,
		Table,
		Spinner,
	},
	setup(props) {
		const storeCompanys = useStoreCompanys();

		const miniSpinner = ref(false);

		const updateCompany = (company: ICompany) => {
			storeCompanys.setCompanyToUpdate(company);
			storeCompanys.setTab("Form");
		};

		const search = (value: string) => {
			storeCompanys.setSearch(value);
			miniSpinner.value = true;
			const TRHEE_SECONDS = 1 * 1000;
			debounce(() => {
				storeCompanys.setSpinner(true);
				miniSpinner.value = false;

				getCompany(storeCompanys.setCompanys, storeCompanys.search, () => {
					storeCompanys.setSpinner(false);
				});
			}, TRHEE_SECONDS);
		};

		const router = useRouter();
		const tableActions = [
			{
				title: "Editar",
				icon: IcEdit,
				onClick: (company: any) => {
					storeCompanys.setCompanyId(company.id);
					router.push(`/company/${company.id}`);
					storeCompanys.setTab("List");
				},
			},
		];

		onUnmounted(() => {
			storeCompanys.setSearch("");
		});

		const columns = [
			{
				title: "Empresa",
				value: "name",
				id: 1,
				sort: true,
				width: "25%",
			},
			{
				title: "CNPJ",
				value: "cnpj",
				id: 3,
				sort: false,
				width: "25%",
			},
			{
				title: "Status",
				value: "statusName",
				id: 3,
				sort: true,
				width: "15%",
			},
			{
				title: "Tipo",
				value: "typeName",
				id: 3,
				sort: true,
				width: "20%",
			},
		];

		// const listDTO = storeCompanys.Companys.map((item: any) => {
		// 	console.log("listagem", item);
		// 	return {
		// 		name: item.name,
		// 		cnpj: item.cnpj
		// 			.replace(/\D/g, "")
		// 			.replace(
		// 				/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
		// 				"$1.$2.$3/$4-$5"
		// 			),
		// 		status: item.status,
		// 		typeName: item.typeName,
		// 	};
		// });

		return {
			columns,
			tableActions,
			storeCompanys,
			miniSpinner,
			Table,
			Spinner,
			search,
			getCompany,
			// listDTO,
		};
	},
});
