import { searchCpfExecutive } from "../companys/service";
import {
	IParticipant,
	participantType,
} from "./../../../interface/participant";
import { getCurrentEvent } from "./../Dashboard/service";
import { importParticipant } from "./components/import-participants/import";
import { ConfirmationError, ParticipantProps, StaffProps } from "./interface";
import {
	sendInvites,
	sendConfirm,
	sendVips,
	deleteParticipants,
	deleteParticipant,
	importList,
	newGetParticipant,
	updateParticipant,
} from "./service";
import { TOKEN_TYPE, eventStore } from "@/store/event.store";
import { SessionStorage } from "@/store/sessionStorage";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

export type typeParticipantStatus =
	| "not invited"
	| "invited"
	| "accepted"
	| "unauthorized"
	| "present"
	| "present at time"
	| "not present"
	| "confirmed";

const initialData: any = {
	status: "not invited",
	gender: "not informed",
	type: "common" as "common" | "staff",
	cpf: "",
	guest_company: undefined,
	name: "",
	credential_name: "",
	classification_id: undefined,
	position_type_id: undefined,
	position: undefined,
	company_id: undefined,
	allows_meeting: true,
	enterprise_mail: "",
	birth_date: null,
	observation: "",
	credential_color: "#000000",
	foreign_document: false
};

const rowsToExportList = [
	{ key: "name", label: "Nome", required: true },
	{ key: "credential_name", label: "Nome na Credencial" },
	{ key: "birth_date", label: "Data de Nascimento" },
	{ key: "cpf", label: "CPF" },
	{ key: "position", label: "Cargo" },
	{ key: "company_id", label: "Empresa" },
	{ key: "table", label: "Mesa" },
	{ key: "position_type_id", label: "Tipo de cargo" },
	{ key: "guest_company", label: "Empresa Convidada" },
	{ key: "status", label: "Situação do Convite" },
	{ key: "enterprise_phone", label: "Telefone Comercial" },
	{ key: "home_phone", label: "Telefone Residencial" },
	{ key: "personal_phone", label: "Telefone Celular" },
	{ key: "personal_mail", label: "Email Pessoal" },
	{ key: "enterprise_mail", label: "Email Comercial" },
	{ key: "register", label: "Matrícula" },
	{ key: "gender", label: "Gênero" },
	{ key: "observation", label: "Observação" },
	{ key: "credential_color", label: "Crachá" },
	{ key: "classification_id", label: "Classificação" },
	{ key: "pre_registration_status", label: "Status da pré-inscrição" },
	{
		key: "pre_registration_payment_status",
		label: "Status do pagamento da pré-inscrição",
	},
	{ key: "pre_registration_amount", label: "Valor da pré-inscrição" },
	{ key: "foreign_document", label: "Documento Estrangeiro" },
];

const { getStoreToken } = eventStore;

const formatCPF = (cpf:string|undefined) => {
	let result = cpf
		?.replace(/\D/g, "")
		?.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
	if((result?.length || 0) != 14){
		result = cpf; // leave unformatted (probably a foreign document)
	}
	return result;
};

export const useStoreParticipantAdmin = defineStore("participant", {
	state: () => ({
		oldListCommon: {} as any,
		common: {} as ParticipantProps,
		commonToExport: [] as any,
		participantCommon: true || false,
		tab: "common" as
			| "common"
			| "staff"
			| "speaker"
			| "form"
			| "import"
			| "export",
		participantToUpdate: initialData,
		search: "",
		status: "",
		spinner: false,
		staff: [] as any,
		speaker: [] as any,
		vips: [] as any,
		hasSearchCpf: false,
		confirmVip: false,
		confirmVipStatus: false,
		confirmDelete: false,
		confirmDeleteOne: false,
		confirmDeleteOneId: "",
		selectExportParticipant: [] as any,
		rowsExport: rowsToExportList,
		participants: [] as any,
		participantType: [] as any,
		successCount: 0,
		error: false,
		domains: {
			companys: [],
			positions: [],
			positionsType: [],
			classifications: [],
			status: [],
		},
		participantPages: {
			page: 0,
			size: "10" as "10" | "25" | "50" | "100",
		},
		staffPages: {
			page: 0,
			size: "10" as "10" | "25" | "50" | "100",
		},
		totalParticipantPageCalc: 0,
		totalStaffPageCalc: 0,
		order: "name",
		direction: "asc" as "asc" | "desc",
		confirmModal: false,
		confirmationErrorList: [] as ConfirmationError[],
		confirmationErrorModal: false,
		listError: {},
	}),
	actions: {
		setOldListCommon(value: any) {
			this.oldListCommon = value;
		},
		setOrder(value: any) {
			this.order = value;
		},
		setDirection(value: "asc" | "desc") {
			this.direction = value;
		},
		setConfirmModal(value: boolean) {
			this.confirmModal = value;
		},
		setCampo(checked: any, value: any) {
			if (checked === "all") {
				this.selectExportParticipant = [];
				this.rowsExport = this.rowsExport.map((el: any) => {
					el.checked = true;
					this.rowsExport.map((el: any) => el.key);
					this.selectExportParticipant.push(el.key);
					return el;
				});
			} else {
				if (checked === "clear") {
					this.selectExportParticipant = [];
					this.rowsExport = this.rowsExport.map((el: any) => {
						el.checked = false;
						return el;
					});
				} else {
					if (!value) {
						if (this.selectExportParticipant.includes(checked)) {
							const index = this.selectExportParticipant.indexOf(checked);
							this.selectExportParticipant.splice(index, 1);
							this.rowsExport = this.rowsExport.map((el: any) => {
								if (el.key === checked) {
									el.checked = false;
								}
								return el;
							});
						}
					} else {
						if (!this.selectExportParticipant.includes(checked)) {
							this.rowsExport = this.rowsExport.map((el: any) => {
								if (el.key === event) {
									el.checked = true;
								}
								return el;
							});
							this.selectExportParticipant.push(checked);
						}
					}
				}
			}
		},
		showConfirmDeleteOne(id: string, confirm: boolean) {
			this.confirmDeleteOne = confirm;
			this.confirmDeleteOneId = id;
		},
		setTotalPages() {
			this.totalParticipantPageCalc = Math.ceil(
				this.common.total / Number(this.participantPages.size)
			);
			this.totalStaffPageCalc = Math.ceil(
				this.common.total / Number(this.participantPages.size)
			);
		},
		onConfirmDeleteOne() {
			this.setSpinner(true);
			deleteParticipant(this.confirmDeleteOneId, () => {
				this.setSpinner(false);
				newGetParticipant(
					this.setCommons,
					this.search,
					this.status,
					() => {
						this.setSpinner(false);
					},
					this.participantPages.page,
					this.participantPages.size,
					{ order: "name", direction: "asc" }
				);
				this.setTab("common");
				this.showConfirmDeleteOne("", false);
			});
		},
		showConfirmDelete(confirm: boolean) {
			this.confirmDelete = confirm;
		},
		onConfirmDelete() {
			if (this.vips.length > 0) {
				this.setSpinner(true);
				deleteParticipants(this.vips, () => {
					this.setSpinner(false);
					this.showConfirmDelete(false);
					this.setVips("clear", false);
					newGetParticipant(
						this.setCommons,
						this.search,
						this.status,
						() => {
							this.setSpinner(false);
						},
						this.participantPages.page,
						this.participantPages.size,
						{ order: "name", direction: "asc" }
					);
				});
			} else {
				useToast().error("Selecione ao menos um participante!");
				this.showConfirmVip(false, false);
			}
		},
		showConfirmVip(confirm: boolean, vip: boolean) {
			this.confirmVip = confirm;
			this.confirmVipStatus = vip;
		},
		onConfirmVips() {
			if (this.vips.length > 0) {
				this.setSpinner(true);
				sendVips(this.vips, this.confirmVipStatus, () => {
					this.setSpinner(false);
					this.showConfirmVip(false, false);
					this.setVips("clear", false);
					newGetParticipant(
						this.setCommons,
						this.search,
						this.status,
						() => {
							this.setSpinner(false);
						},
						this.participantPages.page,
						this.participantPages.size,
						{ order: "name", direction: "asc" }
					);
				});
			} else {
				useToast().error("Selecione ao menos um participante!");
				this.showConfirmVip(false, false);
			}
		},
		setDomains(
			type:
				| "companys"
				| "positions"
				| "positionsType"
				| "classifications"
				| "status",
			domain: any
		) {
			this.domains[type] = domain;
		},
		setVips(checked: any, value: boolean) {
			if (checked === "all") {
				this.vips = [];
				this.common.data = this.common.data.map((el: any) => {
					el.checked = true;
					this.vips.push(el.id);
					return el;
				});
			} else {
				if (checked === "clear") {
					this.vips = [];
					this.common.data = this.common.data.map((el: any) => {
						el.checked = false;
						return el;
					});
				} else {
					if (!value) {
						if (this.vips.includes(checked)) {
							const index = this.vips.indexOf(checked);

							this.vips.splice(index, 1);
							this.common.data = this.common.data.map((el: any) => {
								if (el.id === checked) {
									el.checked = false;
								}
								return el;
							});
						}
					} else {
						if (value) {
							this.vips.push(checked);
							this.common.data = this.common.data.map((el: any) => {
								if (el.id === checked) {
									el.checked = true;
								}
								return el;
							});
						}
					}
				}
			}
		},
		setSpinner(spinner: boolean) {
			this.spinner = spinner;
		},
		setParticipantCommon(type: boolean) {
			this.participantCommon = type;
			if (!this.participantCommon) {
				this.participantToUpdate.type = "staff";
			} else this.participantToUpdate.type = "common";
		},
		setTab(tab: "common" | "staff" | "speaker" | "form" | "import" | "export") {
			this.tab = tab;
		},
		setSearch(search: string) {
			this.search = search;
		},
		setCommons(list: ParticipantProps) {
			this.common = list;
			this.common.total = list.total;
			this.common.data = list.data.map((el) => {
				el.cpfFormat = formatCPF(el.cpf)
				return el;
			});
			this.totalParticipantPageCalc = Math.ceil(
				this.common.total / Number(this.participantPages.size)
			);
		},
		setCommonToExport(list: any) {
			this.commonToExport = list;
			this.commonToExport = list?.map((el: any) => {
				el.cpfFormat = formatCPF(el.cpf)
				return el;
			});
		},
		setStaff(list: StaffProps) {
			this.staff = list;
			this.staff.total = list.total;
			this.staff.data = list.data.map((el: any) => {
				el.cpfFormat = formatCPF(el.cpf)
				el.companyName = el.company?.name;
				return el;
			});
			this.totalStaffPageCalc = Math.ceil(
				this.staff.total / Number(this.staffPages.size)
			);
		},
		setSpeaker(common: any[]) {
			this.speaker = common.map((el) => {
				el.companyName = el.company?.name;
				el.cpfFormat = formatCPF(el.cpf)
				return el;
			});
		},
		setParticipantToUpdate(participant: IParticipant | undefined) {
			if (typeof participant?.status !== "number") {
				this.participantToUpdate = participant ? participant : initialData;
			}
		},

		setParticipantToUpdateDetails(participantId: string | undefined) {
			updateParticipant(participantId, this.setParticipantToUpdate, () => {});
		},

		setHasSearch(search: boolean) {
			this.hasSearchCpf = search;
		},

		setValueInParticipantToUpdate(key: string, value: any) {
			if (!this.participantToUpdate) this.participantToUpdate = {} as any;
			(this.participantToUpdate as any)[key] = value;
		},

		searchCpf(cpf: any) {
			this.setSpinner(true);
			searchCpfExecutive(cpf, this.setParticipantToUpdate, () => {
				this.setSpinner(false);
				this.setHasSearch(true);
				// Removed id to trigger create instead of update and removed id pre-registration
				delete this.participantToUpdate.id;
				delete this.participantToUpdate.pre_registration_id;
				if (!this.participantToUpdate.id)
					this.participantToUpdate.status = "not invited";
			});
		},
		sendInvite(participant: any) {
			let toInvite =
				this.vips.length === 0
					? this.common.data.map((p: any) => p.id)
					: this.vips;

			if (participant) {
				if (participant.status === "accepted") {
					useToast().error(`Participante já confirmado`);
					return;
				}

				toInvite = [participant.id];
			}
			this.setSpinner(true);
			sendInvites(toInvite, () => {
				newGetParticipant(
					this.setCommons,
					this.search,
					this.status,
					() => {
						this.setSpinner(false);
					},
					this.participantPages.page,
					this.participantPages.size,
					{ order: "name", direction: "asc" }
				);
			});
		},
		setConfirmationError(value: any) {
			this.confirmationErrorList = value;
			if (this.confirmationErrorList?.length) {
				return (this.confirmationErrorModal = true);
			}
		},
		confirmAll() {
			const participants = (
				this.vips.length === 0
					? this.common.data
					: this.common.data.filter((el: any) => this.vips.includes(el.id))
			)
				.filter((p: any) => {
					return p.status !== "accepted" && p.status !== "unauthorized";
				})
				.map((p: any) => p.id);
			if (!participants?.length) {
				useToast().success("Participantes Confirmados com sucesso");
				return;
			} else {
				this.setSpinner(true);
				sendConfirm(
					participants,
					() => {
						newGetParticipant(
							this.setCommons,
							this.search,
							this.status,
							() => {
								this.setSpinner(false);
							},
							this.participantPages.page,
							this.participantPages.size,
							{ order: "name", direction: "asc" }
						);
					},
					this.confirmationErrorList
				);
				this.setSpinner(false);
			}
		},
		normalizeNames(data: any) {
			const result: any = {};
			Object.keys(data).forEach((key) => {
				result[key] = (data[key] || "")
					?.toLowerCase()
					?.trim()
					?.normalize("NFD")
					?.replace(/[\u0300-\u036f]/g, "");
			});
			return result;
		},
		isValid(participants: any) {
			const participantNormalize = this?.normalizeNames(participants[0]);

			const isValidated =
				participantNormalize.company === "rede" &&
				participantNormalize.name === "nome" &&
				participantNormalize.credentialName === "nome da credencial" &&
				participantNormalize.cpf === "cpf" &&
				participantNormalize.register === "matricula" &&
				participantNormalize.uf === "uf" &&
				participantNormalize.email === "email" &&
				participantNormalize.position === "cargo" &&
				participantNormalize.enterprise_phone === "telefone comercial" &&
				participantNormalize.home_phone === "telefone residencial" &&
				participantNormalize.personal_phone === "telefone celular" &&
				participantNormalize.birth_date === "data de nascimento" &&
				participantNormalize.guest_company === "empresa convidada" &&
				participantNormalize.position_type === "tipo de cargo" &&
				participantNormalize.classification === "classificacao";
			return isValidated;
		},
		importList(participantType: any, participants: any) {
			this.participantType = participantType;
			this.participants = participants.map((p: any) => {
				Object.keys(p).forEach((key) => {
					if (typeof p[key] !== "string") return;
					p[key] = (p[key] || "").toString().trim();
				});


				p.type = participantType;
				let cpf = (p.cpf || "").toString().replace(/\D/g, "");
				if(cpf.length == 11){
					p.cpf = cpf
				} else {
					// p.cpf is probably a "foreign document"
					p.foreign_document = true
				}

				if (typeof p.birth_date === "string") {
					let [day, month, year] = p.birth_date.toString().split("/");
					year = year.length === 2 ? `19${year}` : year;

					p.birth_date = new Date(year, Number(month) - 1, day);
				}

				return p;
			});

			let event = SessionStorage.get("event");

			if (moment() > moment(event.limit_date) && !event.online) {
				return useToast().error(
					"A data para o cadastro de novos participantes expirou "
				);
			}

			this.setSpinner(true);

			importList(this.participants, (result: any) => {
				this.setSpinner(false);
				if (result.errors.length === 0) {
					this.setTab("common");

					return;
				}
				this.error = true;
				this.successCount = this.participants.length - result.errors.length;
				this.participants = result.errors.map((data: any) => {
					data.participant.error = errorType[data.error];
					return data.participant;
				});
			});
		},
		formImportList(event: any, participantType: any) {
			importParticipant(event, (participants: any) => {
				if (this.isValid(participants)) {
					this.setTab("import");
					this.participants = participants.splice(1, participants.length);
					this.participantType = participantType;
				} else {
					useToast()
						.error(`A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
            rede, nome, nome da credencial, cpf, matricula, uf, email, cargo, telefone comercial, telefone 
            residencial, telefone celular, data de nascimento, empresa convidada, tipo de cargo e classificação`);
				}
			});
		},
	},
});

const errorType: any = {
	"no-name": "Informe o nome",
	"no-company": "Informe uma empresa",
	"no-position-type": "Informe um tipo de cargo",
	"no-position": "Informe um cargo",
	"invalid-company-type": "Informe uma empresa Organizadora",
	"invalid-cpf": "CPF inválido",
	"invalid-position-type": " Tipo de cargo inválido",
	"invalid-classification": "Classificação inválida",
	"cpf-registered": "CPF Registrado",
	"email-required": "Email Obrigatório",
	"email-invalid": "Email Inválido",
};

const rowsExport = [
	{ key: "name", label: "Nome", required: true },
	{ key: "credential_name", label: "Nome na Credencial" },
	{ key: "birth_date", label: "Data de Nascimento" },
	{ key: "cpf", label: "CPF" },
	{ key: "position", label: "Cargo" },
	{ key: "company_id", label: "Empresa" },
	{ key: "table", label: "Mesa" },
	{ key: "position_type_id", label: "Tipo de cargo" },
	{ key: "guest_company", label: "Empresa Convidada" },
	{ key: "status", label: "Situação do Convite" },
	{ key: "enterprise_phone", label: "Telefone Comercial" },
	{ key: "home_phone", label: "Telefone Residencial" },
	{ key: "personal_phone", label: "Telefone Celular" },
	{ key: "personal_mail", label: "Email Pessoal" },
	{ key: "enterprise_mail", label: "Email Comercial" },
	{ key: "register", label: "Matrícula" },
	{ key: "gender", label: "Gênero" },
	{ key: "observation", label: "Observação" },
	{ key: "credential_color", label: "Crachá" },
	{ key: "classification_id", label: "Classificação" },
];
